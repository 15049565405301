












































































import moment from 'moment';
import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import employeeService from '@/services/employee.service';
import CheckEventsConflictsModal from '@/components/timetables/CheckEventsConflictsModal.vue';
import TimetableDayView from '@/components/timetables/TimetableDayView.vue';
import Vue from 'vue';
import { API_DATE_FORMAT, ConflictEventAction } from '@/constants';
import { useEmployeesStore } from '@/store/employees-store';
import { useLocationsStore } from '@/store/locations-store';
import { mapStores } from 'pinia';
import { Timetable } from '@/model/timetable';
import { useTimetablesStore } from '@/store/timetables-store';
import { Location } from '@/model/location';
import { TimetablePanel, useUiStore } from '@/store/ui-store';
import TimetableForm from '@/components/timetables/TimetableForm.vue';

export default Vue.extend({
  components: {
    TimetableDayView,
    BackButtonTitle,
    CheckEventsConflictsModal,
    TimetableForm
  },
  data: () => {
    return {
      actions: [
        ConflictEventAction.CANCEL,
        ConflictEventAction.CREATE_TIMETABLE_DATE_EXCEPTION
      ],
      unsubscribe: null as () => void,
      timetableToEdit: null as Timetable,
      timetableToEditIsDuplicate: false as boolean
    };
  },
  computed: {
    ...mapStores(
      useEmployeesStore,
      useLocationsStore,
      useTimetablesStore,
      useUiStore
    ),
    currentTimetable(): Timetable {
      return this.timetablesStore.currentEntity;
    },
    locations(): Location[] {
      return this.locationsStore.entities;
    },
    validFromHumanized(): string {
      return moment(this.currentTimetable.valid_from).format('DD.MM.YYYY');
    }
  },
  watch: {
    currentTimetable() {
      this.timetableToEdit = null as Timetable;
    }
  },
  created() {
    this.unsubscribe = this.employeesStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'setCurrentEntity') {
          this.closeCurrentPanel();
        }
      });
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    getWeekdayName(weekdayNumber: number): string {
      return moment.weekdays(true)[weekdayNumber - 1];
    },
    closeCurrentPanel() {
      if (this.uiStore.isPanelOpened(TimetablePanel.EDIT_TIMETABLE)) {
        this.uiStore.closePanel(TimetablePanel.EDIT_TIMETABLE);
        this.timetablesStore.unsetCurrentEntity();
      }
    },
    openDeleteTimetableModal() {
      this.$bvModal.show('check-events-conflicts-modal');
    },
    async verifyRequest() {
      await employeeService.verifyDeleteTimetable(this.currentTimetable.id);
    },
    async saveRequest() {
      await this.timetablesStore.delete(
        this.timetablesStore.getById(this.currentTimetable.id)
      );
      return true;
    },
    edit() {
      this.timetableToEdit = {
        ...this.currentTimetable,
        valid_from: moment(this.currentTimetable.valid_from).isBefore(moment())
          ? moment().format(API_DATE_FORMAT)
          : this.currentTimetable.valid_from
      };
      this.timetableToEditIsDuplicate = false;
    },
    duplicate() {
      this.timetableToEdit = {
        ...this.currentTimetable,
        valid_from: ''
      };
      this.timetableToEditIsDuplicate = true;
    },
    saved() {
      this.closeCurrentPanel();
    }
  }
});





























































































































































import moment from 'moment';
import Vue, { PropType } from 'vue';
import { Weekday } from '@/model/weekday';
import { mapStores } from 'pinia';
import { useLocationsStore } from '@/store/locations-store';
import { Location } from '@/model/location';
import { ComputedTimeRange } from '@/model/computed-time-range';
import { TimetableTimeRange } from '@/model/timetable-time-range';
import { TimetableTimeRangeException } from '@/model/timetable-time-range-exception';
import { TimetableDateException } from '@/model/timetable-date-exception';
import { Schedule } from '@/model/schedule';
import { useCalendarStore } from '@/store/calendar-store';
import { useEmployeesStore } from '@/store/employees-store';
import { useTimetableExceptionsStore } from '@/store/timetable-exceptions-store';
import { useTimetablesStore } from '@/store/timetables-store';

export default Vue.extend({
  props: {
    weekday: {
      type: Object as PropType<Weekday | TimetableDateException | Schedule>,
      required: true
    },
    weekdayIndex: {
      type: Number,
      required: true
    },
    displayWeekday: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      moment: moment,
      capacitiesPerTimeRange: [] as number[],
      timeRanges: [] as
        | ComputedTimeRange[]
        | TimetableTimeRange[]
        | TimetableTimeRangeException[] // N'exclut pas les time ranges fermées en mode flexible
    };
  },
  computed: {
    ...mapStores(
      useLocationsStore,
      useCalendarStore,
      useEmployeesStore,
      useTimetableExceptionsStore,
      useTimetablesStore
    ),
    locations(): Location[] {
      return this.locationsStore.entities;
    },
    rawTimeRanges():
      | ComputedTimeRange[]
      | TimetableTimeRange[]
      | TimetableTimeRangeException[] {
      return (
        (this.weekday as Schedule).timeRanges ??
        (this.weekday as Weekday).time_ranges ??
        (this.weekday as TimetableDateException).time_range_exceptions ??
        []
      );
    },
    weekdayName(): string {
      return moment.weekdays(true)[this.weekdayIndex];
    }
  },
  watch: {
    weekday() {
      this.setTimeRanges();
    }
  },
  mounted() {
    this.setTimeRanges();
  },
  methods: {
    async setTimeRanges() {
      this.timeRanges = this.rawTimeRanges;
    }
  }
});

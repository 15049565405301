











































































import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import employeeService from '@/services/employee.service';
import CheckEventsConflictsModal from '@/components/timetables/CheckEventsConflictsModal.vue';
import TimetableExceptionForm from '@/components/timetables/TimetableExceptionForm.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useLocationsStore } from '@/store/locations-store';
import { useTimetableExceptionsStore } from '@/store/timetable-exceptions-store';
import { TimetableDateException } from '@/model/timetable-date-exception';
import { Location } from '@/model/location';
import { useEmployeesStore } from '@/store/employees-store';
import { TimetablePanel, useUiStore } from '@/store/ui-store';
import moment from 'moment';
import { API_DATE_FORMAT } from '@/constants';
import TimetableDayView from '@/components/timetables/TimetableDayView.vue';

export default Vue.extend({
  components: {
    TimetableExceptionForm,
    CheckEventsConflictsModal,
    BackButtonTitle,
    TimetableDayView
  },
  data() {
    return {
      moment: moment,
      unsubscribe: null as () => void,
      exceptionToEdit: null as TimetableDateException,
      exceptionToEditIsDuplicate: false as boolean
    };
  },
  computed: {
    ...mapStores(
      useLocationsStore,
      useTimetableExceptionsStore,
      useEmployeesStore,
      useUiStore
    ),
    currentException(): TimetableDateException {
      return this.timetableExceptionsStore.currentEntity;
    },
    locations(): Location[] {
      return this.locationsStore.entities;
    },
    dateHumanized(): string {
      return moment(this.currentException.date).format('DD.MM.YYYY');
    }
  },
  watch: {
    currentException() {
      this.exceptionToEdit = null as TimetableDateException;
    }
  },
  created() {
    this.unsubscribe = this.employeesStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'setCurrentEntity') {
          this.closeCurrentPanel();
        }
      });
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    getTimetableExceptions() {
      this.timetableExceptionsStore.findAll(this.currentException.employee_id);
    },
    closeCurrentPanel() {
      this.uiStore.closePanel(TimetablePanel.EDIT_TIMETABLE_EXCEPTION);
      this.timetableExceptionsStore.unsetCurrentEntity();
    },
    openDeleteExceptionModal() {
      this.$bvModal.show('check-events-conflicts-modal-delete');
    },
    edit() {
      this.exceptionToEdit = {
        ...this.currentException,
        date: moment(this.currentException.date).isBefore(moment())
          ? moment().format(API_DATE_FORMAT)
          : this.currentException.date
      };
      this.exceptionToEditIsDuplicate = false;
    },
    duplicate() {
      this.exceptionToEdit = {
        ...this.currentException,
        date: ''
      };
      this.exceptionToEditIsDuplicate = true;
    },
    saved() {
      this.closeCurrentPanel();
    },
    async saveRequest() {
      await employeeService.deleteTimetableDateException(
        this.currentException.employee_id,
        this.currentException.date
      );
      await this.getTimetableExceptions();
      return true;
    },
    async verifyRequest() {
      await employeeService.verifyDeleteTimetableDateException(
        this.currentException
      );
    }
  }
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-content"},[_c('div',{staticClass:"dashboard-header row"},[_c('div',{staticClass:"dashboard-header-title col-8 col-md-5 d-flex align-items-center"},[_c('div',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.$t('sidebar.schedules')))]),(!_vm.isFetchingEmployees && _vm.isAdmin)?_c('div',[_c('b-dropdown',{attrs:{"variant":"secondary","text":_vm.currentEmployee.firstname + ' ' + _vm.currentEmployee.lastname}},_vm._l((_vm.getAllWithTimetablesEnabled()),function(employee){return _c('b-dropdown-item',{key:employee.id,attrs:{"active":employee.id === _vm.currentEmployee.id},on:{"click":function($event){return _vm.selectEmployee(employee)}}},[_vm._v(" "+_vm._s(employee.firstname)+" "+_vm._s(employee.lastname)+" ")])}),1)],1):_vm._e()]),_c('div',{staticClass:"dashboard-header-actions col-4 col-md mt-3 mt-md-0 text-md-right"},[(_vm.currentEmployee && _vm.currentEmployee.id)?_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.addTimetableOrTimetableException()}}},[_c('i',{staticClass:"fa fa-plus d-none d-md-inline-block"}),_vm._v(" "+_vm._s(_vm.$t('button.add'))+" ")]):_vm._e()])]),(_vm.currentEmployee && _vm.currentEmployee.id)?_c('nav',{staticClass:"nav mb-2"},[_c('a',{staticClass:"nav-link pointer",class:{
        'tw-bg-white tw-rounded-md tw-font-medium':
          _vm.viewType === _vm.TimetableViewType.TIMETABLE
      },on:{"click":function($event){return _vm.openViewType(_vm.TimetableViewType.TIMETABLE)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('timetables.title')))])]),_c('a',{staticClass:"nav-link pointer",class:{
        'tw-bg-white tw-rounded-md tw-font-medium':
          _vm.viewType === _vm.TimetableViewType.TIMETABLE_EXCEPTIONS
      },on:{"click":function($event){return _vm.openViewType(_vm.TimetableViewType.TIMETABLE_EXCEPTIONS)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('timetableExceptions.title')))])])]):_vm._e(),_c('div',{staticClass:"panels with-tabs"},[_c('timetables-employees',{staticClass:"panel",class:{ 'd-none d-lg-block': _vm.currentPanelIndex !== 0 }}),(
        _vm.isPanelOpened(_vm.TIMETABLE_PANEL.EDIT_TIMETABLE) && _vm.currentTimetable.id
      )?_c('timetable-view',{staticClass:"panel panel-2_3",class:{ 'd-none d-lg-block': _vm.currentPanelIndex !== 1 }}):_vm._e(),(_vm.isPanelOpened(_vm.TIMETABLE_PANEL.ADD_TIMETABLE))?_c('timetable-add',{staticClass:"panel panel-2_3",class:{ 'd-none d-lg-block': _vm.currentPanelIndex !== 1 }}):_vm._e(),(_vm.isPanelOpened(_vm.TIMETABLE_PANEL.ADD_TIMETABLE_EXCEPTION))?_c('timetable-exception-add',{staticClass:"panel panel-2_3",class:{ 'd-none d-lg-block': _vm.currentPanelIndex !== 1 }}):_vm._e(),(_vm.isPanelOpened(_vm.TIMETABLE_PANEL.EDIT_TIMETABLE_EXCEPTION))?_c('timetable-exception-view',{staticClass:"panel panel-2_3",class:{ 'd-none d-lg-block': _vm.currentPanelIndex !== 1 }}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
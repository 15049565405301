

























































































import CheckEventsConflictsModal from '@/components/timetables/CheckEventsConflictsModal.vue';
import DateInput from '@/components/shared/forms/DateInput.vue';
import TimetableDayForm from '@/components/timetables/TimetableDayForm.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import { ValidationObserver } from 'vee-validate';
import moment from 'moment';
import { Weekday } from '@/model/weekday';
import { API_TIME_FORMAT, ConflictEventAction } from '@/constants';
import Vue, { PropType } from 'vue';
import { mapStores } from 'pinia';
import { useEmployeesStore } from '@/store/employees-store';
import { useTimetablesStore } from '@/store/timetables-store';
import { AlertType, TimetablePanel, useUiStore } from '@/store/ui-store';
import { Employee } from '@/model/employee';
import { Timetable } from '@/model/timetable';
import TimetableHelper from '@/helpers/timetable.helper';
import cloneDeep from 'lodash/cloneDeep';
import employeeService from '@/services/employee.service';
import Alert from '@/components/shared/Alert.vue';

export default Vue.extend({
  components: {
    CheckEventsConflictsModal,
    DateInput,
    TimetableDayForm,
    SubmitButton,
    ValidationObserver,
    Alert
  },
  props: {
    timetableToEdit: {
      type: Object as PropType<Timetable>,
      required: false,
      default: () => null as Timetable | null
    },
    isDuplicate: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      today: moment().toDate(),
      moment: moment,
      weekdays: moment.weekdays(true),
      timetableForm: {
        valid_from: '',
        employee_id: '' as number | string,
        weekdays: [] as Partial<Weekday>[]
      },
      validationErrors: {},
      actions: [
        ConflictEventAction.CANCEL,
        ConflictEventAction.CREATE_TIMETABLE_DATE_EXCEPTION
      ],
      AlertType: AlertType
    };
  },
  computed: {
    ...mapStores(useEmployeesStore, useTimetablesStore, useUiStore),
    employee(): Employee {
      return this.employeesStore.currentEntity;
    },
    isSaving(): boolean {
      return this.employeesStore.saving;
    },
    timetable(): Timetable {
      return this.timetablesStore.currentEntity;
    },
    weeklyHoursPlanned(): number {
      let minutes = 0;
      this.timetableForm.weekdays.forEach(weekday => {
        if (weekday.time_ranges) {
          minutes += TimetableHelper.getMinutesPlannedForTimeSlots(
            weekday.time_ranges
          );
        }
      });
      return +(minutes / 60).toFixed(2);
    },
    firstAvailableWeekdayIndex(): number {
      return this.timetableForm.weekdays.findIndex(
        weekday => Object.keys(weekday).length > 0 && weekday.available
      );
    },
    overriddenTimetable(): Timetable | undefined {
      const validFrom = moment(this.timetableForm.valid_from);
      return this.timetablesStore.entities.find(timetable => {
        return moment(timetable.valid_from).isSame(validFrom, 'day');
      });
    },
    willOverride(): boolean {
      if (this.timetableForm.valid_from) {
        return this.overriddenTimetable !== undefined;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.timetableForm.employee_id = this.employee.id;
    this.weekdays.forEach(() => {
      this.timetableForm.weekdays.push({});
    });
    if (this.timetableToEdit) {
      this.fillFormData(this.timetableToEdit);
    }
  },
  methods: {
    fillFormData(timetableToEdit: Timetable) {
      const timetable: Timetable = cloneDeep(timetableToEdit);
      this.timetableForm.employee_id = timetable.employee_id;
      this.timetableForm.valid_from = timetable.valid_from;
      timetable.weekdays.forEach((weekday: Weekday, index: number) => {
        weekday.min_start = weekday.min_start
          ? moment(weekday.min_start).format(API_TIME_FORMAT)
          : '';
        weekday.max_end = weekday.max_end
          ? moment(weekday.max_end).format(API_TIME_FORMAT)
          : '';
        weekday.capacity_threshold = weekday.capacity_threshold * 100;
        weekday.time_ranges = weekday.time_ranges.map(
          (timeRange, timeRangeIndex) => {
            return {
              ...timeRange,
              duration: timeRange.duration
                ? moment(timeRange.duration).format(API_TIME_FORMAT)
                : '',
              break: weekday.time_ranges[timeRangeIndex - 1]?.break
                ? moment(weekday.time_ranges[timeRangeIndex - 1]?.break).format(
                    API_TIME_FORMAT
                  )
                : '',
              start: timeRange.start
                ? moment(timeRange.start).format(API_TIME_FORMAT)
                : '',
              end: timeRange.end
                ? moment(timeRange.end).format(API_TIME_FORMAT)
                : ''
            };
          }
        );
        this.updateWeekday(weekday, index);
      });
    },
    onChangeValidFrom(value: string) {
      this.timetableForm.valid_from = value;
      if (this.willOverride) {
        this.fillFormData(this.overriddenTimetable);
      } else if (!this.isDuplicate) {
        this.resetForm();
      }
    },
    resetForm() {
      this.timetableForm.weekdays = [];
      this.weekdays.forEach((weekday, index) => {
        this.updateWeekday(
          {
            weekday: index + 1,
            flexible: false,
            available: false,
            time_ranges: []
          },
          index
        );
      });
    },
    getTimetables() {
      return this.timetablesStore.findAll(this.employee.id);
    },
    updateWeekday(weekday: Partial<Weekday>, index: number) {
      Vue.set(this.timetableForm.weekdays, index, weekday);
    },
    repeatForNextDays(index: number) {
      for (
        let weekdayIndex = index + 1;
        weekdayIndex < this.timetableForm.weekdays.length;
        weekdayIndex++
      ) {
        Vue.set(
          this.timetableForm.weekdays,
          weekdayIndex,
          cloneDeep({
            ...this.timetableForm.weekdays[index],
            weekday: weekdayIndex + 1
          })
        );
      }
    },
    getTimetableDto() {
      const timetableDto = cloneDeep(this.timetableForm);
      for (let i = 0; i < timetableDto.weekdays.length; i++) {
        timetableDto.weekdays[i] = this.$refs.weekday[i].dayDto;
      }
      return timetableDto;
    },
    async verifyRequest() {
      await employeeService.verifyAddTimetable(this.getTimetableDto());
    },
    async saveRequest() {
      return this.timetablesStore.create(this.getTimetableDto());
    },
    setValidationErrors(errors, message) {
      this.validationErrors = errors;
      this.$bvModal.hide('check-events-conflicts-modal');
      this.uiStore.alert(message);
    },
    save(form) {
      form.validate();
      if (!form.valid) return;
      this.$bvModal.show('check-events-conflicts-modal');
    },
    saved() {
      this.timetablesStore.unsetCurrentEntity();
      this.uiStore.closePanel(TimetablePanel.ADD_TIMETABLE);
    }
  }
});

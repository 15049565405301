var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.weekday.flexible)?_c('div',{staticClass:"tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between tw-gap-2 md:tw-gap-0"},[_c('span',{staticClass:"tw-font-bold tw-capitalize tw-w-1/5"},[_vm._v(_vm._s(_vm.weekdayName))]),(_vm.timeRanges.length > 0)?_c('div',{staticClass:"md:tw-flex-grow tw-flex tw-flex-col md:tw-flex-row md:tw-justify-start tw-gap-3"},_vm._l((_vm.timeRanges),function(timeRange,index){return _c('div',{key:index,staticClass:"tw-flex tw-gap-4"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-1 md:tw-gap-2"},[_c('div',{staticClass:"tw-text-sm tw-font-bold"},[_vm._v(" "+_vm._s(_vm._f("date")(timeRange.start,'HH:mm'))+" - "+_vm._s(_vm._f("date")(timeRange.end,'HH:mm'))+" ")]),(_vm.locations.length > 1)?_c('div',{staticClass:"tw-px-2 tw-py-1 tw-rounded-md tw-text-xs tw-font-medium",class:[
              'tw-bg-' + timeRange.location.color + '-100',
              'tw-text-' + timeRange.location.color + '-800'
            ]},[_c('i',{staticClass:"fas fa-map-marker-alt tw-mr-2"}),_c('span',{},[_vm._v(_vm._s(timeRange.location.name))])]):_vm._e(),(timeRange.own_category)?_c('div',{},[_c('i',{staticClass:"fas fa-car tw-mr-1"}),_c('span',{},[_vm._v(_vm._s(timeRange.own_category.name))])]):_vm._e()]),(
            _vm.isFromLPittet
              ? (index + 1) % 2 !== 0 && index !== _vm.timeRanges.length - 1
              : (index + 1) % 3 !== 0 && index !== _vm.timeRanges.length - 1
          )?_c('div',{staticClass:"tw-h-full tw-w-px tw--ml-px tw-bg-medium"}):_vm._e()])}),0):_c('div',{staticClass:"tw-flex-grow tw-text-medium"},[(_vm.weekday.summary)?_c('span',[_vm._v(_vm._s(_vm.weekday.summary))]):_c('span',[_vm._v(_vm._s(_vm.$t('timetables.notAvailable')))])])]):_vm._e(),(_vm.weekday.flexible)?_c('div',[_c('div',{staticClass:"tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-gap-2 md:tw-gap-0"},[_c('div',{staticClass:"tw-font-bold tw-capitalize tw-w-1/5"},[_c('div',[_vm._v(_vm._s(_vm.weekdayName))]),_c('div',{staticClass:"tw-inline-block tw-px-1 tw-py-0.5 tw-rounded tw-bg-success-600 tw-uppercase tw-text-white tw-text-center tw-text-2xs"},[_vm._v(" "+_vm._s(_vm.$t('label.flexible'))+" ")])]),_c('div',{staticClass:"tw-flex-grow tw-flex tw-flex-col tw-gap-4"},[_c('div',{staticClass:"tw-flex tw-flex-col md:tw-flex-row tw-justify-start md:tw-gap-4"},_vm._l((_vm.timeRanges),function(timeRange,index){return _c('div',{key:timeRange.start,staticClass:"tw-flex tw-flex-col md:tw-flex-row tw-justify-start tw-gap-2 md:tw-gap-4"},[_c('div',{staticClass:"tw-max-w-1/5 tw-p-2 md:tw-p-0 tw-flex tw-flex-col tw-items-start tw-gap-2 tw-rounded-md tw-border md:tw-border-0 tw-border-light"},[_c('div',[_c('div',[_c('i',{staticClass:"fas fa-clock tw-mr-2"}),_vm._v(" "+_vm._s(_vm._f("date")(timeRange.duration,'H[h]mm'))+" ")])]),_c('div',{staticClass:"tw-text-xs tw-text-medium  tw-font-medium"},[(index === 0)?_c('div',{},[_vm._v(" "+_vm._s(_vm.$t('timetable.min_start'))+" "+_vm._s(_vm._f("date")(_vm.weekday.min_start,'HH:mm'))+" ")]):_vm._e(),(_vm.timeRanges.length - 1 === index)?_c('div',{},[_vm._v(" "+_vm._s(_vm.$t('timetable.max_end'))+" "+_vm._s(_vm._f("date")(_vm.weekday.max_end,'HH:mm'))+" ")]):_vm._e()]),(_vm.locations.length > 1)?_c('div',{staticClass:"tw-px-2 tw-py-1 tw-rounded-md tw-text-xs tw-font-medium",class:[
                  'tw-bg-' + timeRange.location.color + '-100',
                  'tw-text-' + timeRange.location.color + '-800'
                ]},[_c('i',{staticClass:"fas fa-map-marker-alt tw-mr-2"}),_c('span',{},[_vm._v(_vm._s(timeRange.location.name))])]):_vm._e()]),(timeRange.break)?_c('div',{staticClass:"tw-hidden md:tw-block tw-w-px tw-h-full tw--ml-px tw-bg-medium"}):_vm._e(),(timeRange.break)?_c('div',{staticClass:"tw-p-4 tw-rounded-md tw-bg-light tw-text-sm"},[_c('div',[_c('i',{staticClass:"fas fa-clock tw-mr-2"}),_c('span',{staticClass:"tw-font-bold"},[_vm._v(_vm._s(_vm._f("date")(timeRange.break,'H[h]mm')))])]),_c('div',{staticClass:"tw-mt-2 tw-text-medium tw-text-xs"},[_vm._v(" "+_vm._s(_vm.$t('timetable.break'))+" ")])]):_vm._e(),(
                _vm.isFromLPittet
                  ? (index + 1) % 2 !== 0 && index !== _vm.timeRanges.length - 1
                  : (index + 1) % 3 !== 0 && index !== _vm.timeRanges.length - 1
              )?_c('div',{staticClass:"tw-w-px tw-h-full tw--ml-px tw-bg-medium"}):_vm._e()])}),0),(_vm.weekday.capacity_threshold)?_c('div',{staticClass:"tw-text-medium tw-text-xs tw-font-medium"},[_c('i',{staticClass:"fas fa-info-circle tw-mr-2"}),_vm._v(" "+_vm._s(_vm.$t('timetable.capacityThreshold', { threshold: _vm.weekday.capacity_threshold * 100 }))+" ")]):_vm._e()])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
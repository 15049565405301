













import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import TimetablesList from '@/components/timetables/TimetablesList.vue';
import TimetableExceptionsList from '@/components/timetables/TimetableExceptionsList.vue';
import Vue from 'vue';
import {
  TimetableViewType,
  useTimetablesStore
} from '@/store/timetables-store';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { useEmployeesStore } from '@/store/employees-store';
import { Employee } from '@/model/employee';
import { Timetable } from '@/model/timetable';

export default Vue.extend({
  components: {
    TimetableExceptionsList,
    TimetablesList,
    LoadingIndicator
  },
  data() {
    return {
      TimetableViewType: TimetableViewType
    };
  },
  computed: {
    ...mapStores(useUsersStore, useEmployeesStore, useTimetablesStore),
    loggedInEmployeeId(): number | null {
      return this.usersStore.loggedInEmployeeId;
    },
    isFetchingEmployees(): boolean {
      return this.employeesStore.fetching;
    },
    employees(): Employee[] {
      return this.employeesStore.entities;
    },
    employee(): Employee {
      return this.employeesStore.currentEntity;
    },
    isFetchingTimetables(): boolean {
      return this.timetablesStore.fetching;
    },
    errorOccurred(): boolean {
      return this.timetablesStore.failure;
    },
    timetables(): Timetable[] {
      return this.timetablesStore.entities;
    },
    viewType(): TimetableViewType {
      return this.timetablesStore.viewType;
    }
  }
});

































































































import TimetableView from '@/components/timetables/TimetableView.vue';
import TimetableAdd from '@/components/timetables/TimetableAdd.vue';
import TimetableExceptionAdd from '@/components/timetables/TimetableExceptionAdd.vue';
import TimetableExceptionView from '@/components/timetables/TimetableExceptionView.vue';
import TimetablesEmployees from '@/components/timetables/TimetablesEmployees.vue';
import employeeCanSetTimetableGuard from '@/guards/employee-can-set-timetable.guard';
import Vue from 'vue';
import {
  Panel,
  TIMETABLE_PANELS_LAYOUT,
  TimetablePanel,
  useUiStore
} from '@/store/ui-store';
import {
  TimetableViewType,
  useTimetablesStore
} from '@/store/timetables-store';
import { useUsersStore } from '@/store/users-store';
import { useEmployeesStore } from '@/store/employees-store';
import { mapStores } from 'pinia';
import { Employee } from '@/model/employee';
import { Timetable } from '@/model/timetable';
import { useTimetableExceptionsStore } from '@/store/timetable-exceptions-store';

export default Vue.extend({
  beforeRouteEnter: employeeCanSetTimetableGuard,
  components: {
    TimetableExceptionView,
    TimetableExceptionAdd,
    TimetableAdd,
    TimetableView,
    TimetablesEmployees
  },
  data() {
    return {
      TIMETABLE_PANEL: TimetablePanel,
      TimetableViewType: TimetableViewType,
      unsubscribeFromStore: null as any
    };
  },
  computed: {
    ...mapStores(
      useUsersStore,
      useUiStore,
      useEmployeesStore,
      useTimetablesStore,
      useTimetableExceptionsStore
    ),
    loggedInEmployeeId(): number {
      return this.usersStore.loggedInEmployeeId as number;
    },
    openedPanels(): string[] {
      return this.uiStore.openedPanels;
    },
    isFetchingEmployees(): boolean {
      return this.employeesStore.fetching;
    },
    currentTimetable(): Timetable {
      return this.timetablesStore.currentEntity;
    },
    currentEmployee(): Employee {
      return this.employeesStore.currentEntity;
    },
    employees(): Employee[] {
      return this.employeesStore.entities;
    },
    viewType(): TimetableViewType {
      return this.timetablesStore.viewType;
    },
    currentPanelIndex(): number {
      return this.uiStore.currentPanelIndex;
    },
    currentPanel(): Panel {
      return this.uiStore.currentPanel;
    }
  },
  created() {
    this.uiStore.openPanel(
      TIMETABLE_PANELS_LAYOUT,
      TimetablePanel.TIMETABLES_LIST
    );
    if (!this.currentEmployee?.id) {
      const unsubscribe = this.employeesStore.$onAction(({ name }) => {
        if (name === 'setCurrentEntity' && this.currentEmployee?.id) {
          this.getTimetables();
          this.getTimetableExceptions();
          unsubscribe();
        }
      });
    } else {
      if (!this.currentEmployee.timetable) {
        this.employeesStore.setCurrentEntity(
          this.employeesStore.getAllWithTimetablesEnabled()[0]
        );
      }
      this.getTimetables();
      this.getTimetableExceptions();
    }
  },
  /**
   * A la fermeture de la page, on réinitialise la vue afin de ne pas entrer à nouveau
   */
  destroyed() {
    this.uiStore.closePanel(TimetablePanel.EDIT_TIMETABLE);
    this.timetablesStore.unsetCurrentEntity();
  },
  methods: {
    getAllWithTimetablesEnabled(): Employee[] {
      return this.employeesStore.getAllWithTimetablesEnabled();
    },
    getTimetables() {
      this.timetablesStore.findAll(this.currentEmployee.id);
    },
    getTimetableExceptions() {
      this.timetableExceptionsStore.findAll(this.currentEmployee.id);
    },
    isPanelOpened(panel: Panel) {
      return this.uiStore.isPanelOpened(panel);
    },
    addTimetableOrTimetableException() {
      if (this.viewType === this.TimetableViewType.TIMETABLE) {
        this.timetablesStore.$reset();
        this.uiStore.openPanel(
          TIMETABLE_PANELS_LAYOUT,
          TimetablePanel.ADD_TIMETABLE
        );
      } else {
        this.timetableExceptionsStore.$reset();
        this.uiStore.openPanel(
          TIMETABLE_PANELS_LAYOUT,
          TimetablePanel.ADD_TIMETABLE_EXCEPTION
        );
      }
    },
    openViewType(viewType: TimetableViewType) {
      this.timetablesStore.setViewType(viewType);
      if (this.currentPanelIndex > 0) {
        this.uiStore.closePanel(this.currentPanel);
      }
    },
    selectEmployee(employee: Employee) {
      this.employeesStore.setCurrentEntity(employee);
      this.timetablesStore.$reset();
      this.getTimetables();
      this.getTimetableExceptions();
    }
  }
});

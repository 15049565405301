














import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import TimetableForm from '@/components/timetables/TimetableForm.vue';
import Vue from 'vue';
import { TimetablePanel, useUiStore } from '@/store/ui-store';
import { mapStores } from 'pinia';
import { useEmployeesStore } from '@/store/employees-store';

export default Vue.extend({
  components: {
    BackButtonTitle,
    TimetableForm
  },
  data() {
    return {
      unsubscribe: null as () => void
    };
  },
  computed: {
    ...mapStores(useEmployeesStore, useUiStore)
  },
  created() {
    this.unsubscribe = this.employeesStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'setCurrentEntity') {
          this.closeCurrentPanel();
        }
      });
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    closeCurrentPanel() {
      this.uiStore.closePanel(TimetablePanel.ADD_TIMETABLE);
    }
  }
});










































import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import moment from 'moment';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useEmployeesStore } from '@/store/employees-store';
import { Employee } from '@/model/employee';
import { Timetable } from '@/model/timetable';
import {
  TimetableViewType,
  useTimetablesStore
} from '@/store/timetables-store';
import {
  TIMETABLE_PANELS_LAYOUT,
  TimetablePanel,
  useUiStore
} from '@/store/ui-store';
import ConfigListElement from '@/components/shared/ConfigListElement.vue';
import { Collection } from '@/store/common/collections';

export default Vue.extend({
  components: {
    EmptyDataset,
    LoadingIndicator,
    ConfigListElement
  },
  data() {
    return {
      COLLECTION: Collection
    };
  },
  computed: {
    ...mapStores(useEmployeesStore, useTimetablesStore, useUiStore),
    isFetchingEmployees(): boolean {
      return this.employeesStore.fetching;
    },
    employees(): Employee[] {
      return this.employeesStore.entities;
    },
    currentEmployee(): Employee {
      return this.employeesStore.currentEntity;
    },
    isFetchingTimetables(): boolean {
      return this.timetablesStore.fetching;
    },
    errorOccurred(): boolean {
      return this.timetablesStore.failure;
    },
    timetables(): Timetable[] {
      return this.timetablesStore.entities;
    },
    viewType(): TimetableViewType {
      return this.timetablesStore.viewType;
    },
    currentTimetable(): Timetable {
      return this.timetablesStore.currentEntity;
    }
  },
  created() {
    if (this.currentTimetable?.id) {
      this.editTimetable(this.currentTimetable);
    }
  },
  methods: {
    editTimetable(timetable: Timetable) {
      this.timetablesStore.setCurrentEntity(timetable);
      this.uiStore.openPanel(
        TIMETABLE_PANELS_LAYOUT,
        TimetablePanel.EDIT_TIMETABLE
      );
    },
    isCurrentlyUsedTimetable(timetable: Timetable): boolean {
      return (
        moment(timetable.valid_from) <= moment() &&
        !this.timetables.find(timetableFromLoop => {
          return (
            moment(timetableFromLoop.valid_from) <= moment() &&
            moment(timetableFromLoop.valid_from).isAfter(
              moment(timetable.valid_from)
            )
          );
        })
      );
    }
  }
});

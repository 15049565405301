var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(form){return [(
        _vm.timetableToEdit &&
          !_vm.isDuplicate &&
          _vm.moment(_vm.timetableToEdit.valid_from).isSame(_vm.moment(), 'day')
      )?_c('alert',{attrs:{"variant":_vm.AlertType.INFO,"message":_vm.$t('timetable.editInfo.validFromIsToday')}}):_vm._e(),(_vm.willOverride && !_vm.timetableToEdit)?_c('alert',{attrs:{"message":_vm.$t('timetable.willOverride', {
          validFrom: _vm.moment(_vm.timetableForm.valid_from).format(
            'ddd DD MMM YYYY'
          )
        }),"variant":_vm.AlertType.INFO}}):_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[((_vm.timetableToEdit && _vm.isDuplicate) || !_vm.timetableToEdit)?_c('div',{staticClass:"w-50"},[_c('date-input',{attrs:{"value":_vm.timetableForm.valid_from,"errors":_vm.validationErrors,"min-date":_vm.today,"name":"valid_from","label":_vm.$t('label.validFrom')},on:{"onValueChange":_vm.onChangeValidFrom}})],1):_vm._e(),(_vm.weeklyHoursPlanned > 0)?_c('div',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t('timetables.total'))+" "),_c('span',{staticClass:"badge badge-secondary ml-2"},[_vm._v(_vm._s(_vm.weeklyHoursPlanned)+" h")])]):_vm._e(),(_vm.timetableForm.valid_from)?_c('div',[_vm._l((_vm.weekdays),function(weekday,index){return _c('timetable-day-form',{key:weekday,ref:"weekday",refInFor:true,attrs:{"validation-errors":_vm.validationErrors.weekdays ? _vm.validationErrors.weekdays[index] : {},"weekday":_vm.timetableForm.weekdays[index],"weekday-index":index,"first-available-weekday-index":_vm.firstAvailableWeekdayIndex},on:{"onDayChanged":function($event){return _vm.updateWeekday($event, index)},"repeatForNextDays":function($event){return _vm.repeatForNextDays(index)}}})}),_c('div',{staticClass:"mt-3"},[_c('submit-button',{attrs:{"loading":_vm.isSaving,"variant":"primary"},on:{"onClick":function($event){return _vm.save(form)}}})],1)],2):_vm._e()])]}}])}),_c('check-events-conflicts-modal',{attrs:{"title":_vm.timetableToEdit || _vm.willOverride
        ? _vm.$t('timetable.edit')
        : _vm.$t('timetable.add'),"verify":_vm.verifyRequest,"save":_vm.saveRequest,"set-validation-errors":_vm.setValidationErrors,"actions":_vm.actions,"button-text":_vm.$t('button.add')},on:{"saved":_vm.saved}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }


































































import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useEmployeesStore } from '@/store/employees-store';
import { Employee } from '@/model/employee';
import { useTimetableExceptionsStore } from '@/store/timetable-exceptions-store';
import { TimetableDateException } from '@/model/timetable-date-exception';
import {
  TIMETABLE_PANELS_LAYOUT,
  TimetablePanel,
  useUiStore
} from '@/store/ui-store';
import ConfigListElement from '@/components/shared/ConfigListElement.vue';
import { Collection } from '@/store/common/collections';

export default Vue.extend({
  components: {
    EmptyDataset,
    LoadingIndicator,
    ConfigListElement
  },
  data() {
    return {
      currentPage: 1,
      past: false,
      COLLECTION: Collection
    };
  },
  computed: {
    ...mapStores(useEmployeesStore, useTimetableExceptionsStore, useUiStore),
    currentEmployee(): Employee {
      return this.employeesStore.currentEntity;
    },
    isFetchingTimetableExceptions(): boolean {
      return this.timetableExceptionsStore.fetching;
    },
    errorOccurred(): boolean {
      return this.timetableExceptionsStore.failure;
    },
    timetableExceptions(): TimetableDateException[] {
      return this.timetableExceptionsStore.entities;
    },
    currentTimetableException(): TimetableDateException {
      return this.timetableExceptionsStore.currentEntity;
    },
    pageCount(): number {
      return this.employeesStore.pagination.page_count;
    },
    rows(): number {
      return this.employeesStore.pagination.count;
    }
  },
  created() {
    if (this.currentTimetableException?.id) {
      this.editTimetableException(this.currentTimetableException);
    }
  },
  methods: {
    setFilterPast(past: boolean) {
      this.past = past;
      this.fetchDateExceptions(this.currentPage);
    },
    fetchDateExceptions(page: number) {
      this.currentPage = page;
      this.timetableExceptionsStore.findAllPaginated(
        this.currentEmployee.id,
        this.past,
        {
          page,
          force: true
        }
      );
    },
    editTimetableException(exception: TimetableDateException) {
      this.timetableExceptionsStore.setCurrentEntity(exception);
      this.uiStore.openPanel(
        TIMETABLE_PANELS_LAYOUT,
        TimetablePanel.EDIT_TIMETABLE_EXCEPTION
      );
    }
  }
});

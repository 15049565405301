import employeeService from '@/services/employee.service';
import router from '@/routes';
import { useUsersStore } from '@/store/users-store';
import { useUiStore } from '@/store/ui-store';

export default async function(to, from, next) {
  const usersStore = useUsersStore();
  if (usersStore.isAdmin) {
    next();
    return;
  }
  const employeeId = usersStore.loggedInEmployeeId;
  if (!employeeId) {
    next(false);
    return;
  }

  const employee = await employeeService.getById(employeeId);
  if (employee.can_set_timetable) {
    next();
  } else {
    useUiStore().alert(router.app.$t('guard.insufficientPermissions'));
    next({ name: 'Home' });
  }
}

















import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import moment from 'moment';
import WeekdayBase from '@/components/shared/WeekdayBase.vue';
import TimetableHelper from '@/helpers/timetable.helper';
import TimetableExceptionForm from '@/components/timetables/TimetableExceptionForm.vue';
import Vue, { VueConstructor } from 'vue';
import { DEFAULT_TIME_SLOT } from '@/constants';
import { Role } from '@/model/role';
import { mapStores } from 'pinia';
import { useEmployeesStore } from '@/store/employees-store';
import { useCategoriesStore } from '@/store/categories-store';
import { Employee } from '@/model/employee';
import { InputOption } from '@/model/input-option';
import { useUsersStore } from '@/store/users-store';
import { TimetablePanel, useUiStore } from '@/store/ui-store';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof WeekdayBase>
>).extend({
  name: 'TimetableExceptionAdd',
  components: {
    TimetableExceptionForm,
    BackButtonTitle
  },
  extends: WeekdayBase,
  props: {
    editingTimetableException: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      date: null as string | null,
      available: true,
      summary: null as string | null,
      timeRanges: [DEFAULT_TIME_SLOT],
      ROLE: Role,
      hovering: {
        topArrow: false,
        bottomArrow: false
      },
      sendingData: false,
      validationErrors: {},
      today: moment().toDate(),
      weekdayIndex: null as number | null,
      unsubscribe: null as () => void
    };
  },
  computed: {
    ...mapStores(
      useEmployeesStore,
      useCategoriesStore,
      useUsersStore,
      useUiStore
    ),
    employee(): Employee {
      return this.employeesStore.currentEntity;
    },
    categories(): InputOption[] {
      return this.categoriesStore.getAsSelectOptions;
    },
    isFromLPittet(): boolean {
      return this.usersStore.isFromLPittet;
    },
    dailyHoursPlanned(): number {
      return (
        TimetableHelper.getMinutesPlannedForTimeSlots(this.timeRanges) / 60
      );
    }
  },
  created() {
    this.unsubscribe = this.employeesStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'setCurrentEntity') {
          this.closeCurrentPanel();
        }
      });
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    closeCurrentPanel() {
      this.uiStore.closePanel(TimetablePanel.ADD_TIMETABLE_EXCEPTION);
    },
    async saved() {
      this.closeCurrentPanel();
    }
  }
});
